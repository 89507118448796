.app__skills {
  flex: 1;
  width: 100%;
  flex-direction: column;
  margin-top: 2rem;
}

.app__skills-container {
  width: 90%;
  margin-top: 3rem;

  display: flex;
  flex-direction: row;

  @media screen and (max-width: 900px) {
    width: 100%;
    flex-direction: column;
  }
}

.app__skills-list {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  margin-right: 5rem;

  @media screen and (max-width: 1500px) {
    margin-right: 3rem;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 900px) {
    margin-right: 0;
    justify-content: center;
    align-items: center;
  }
}

.app__skills-item {
  flex-direction: column;
  text-align: center;

  margin: .5rem;

  div {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: hsla(0, 0%, 100%, 0.035);
    backdrop-filter: blur(5px);
    -moz-backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    border-radius: 0.25rem;

    img {
      width: 50%;
      height: 50%;
    }

    &:hover {
      box-shadow: 0 0 25px #ffffff08;
    }

    @media screen and (min-width: 2000px) {
      width: 100px;
      height: 100px;
    }

    @media screen and (max-width: 1500px) {
      width: 60px;
      height: 60px;
    }

    @media screen and (max-width: 550px) {
      width: 60px;
      height: 60px;
    }
  }

  p {
    font-weight: 500;
    margin-top: 0.5rem;
    color: var(--secondary-color);
  }

  @media screen and (min-width: 2000px) {
    margin: 1rem 2rem;

    p {
      margin-top: 1rem;
    }
  }

  @media screen and (max-width: 1500px) {
    margin: 0.5rem;
  }

  @media screen and (max-width: 650px) {
    margin: 0.5rem;
  }
}

.app__skills-exp {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  @media screen and (max-width: 900px) {
    margin-top: 2rem;
  }
}

.app__skills-exp-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  margin: .5rem 0;
}

.app__skills-exp-works {
  flex: 1;

  .app__skills-exp-work {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 1rem;
    cursor: pointer;

    h4 {
      font-weight: 500;
    }

    p {
      font-weight: 400;
      color: var(--primary-color);
      margin-top: 5px;
    }
  }
}

.app__skills-exp-year {
  margin-right: 3rem;

  p {
    font-weight: 800;
    color: var(--secondary-color);
  }

  @media screen and (max-width: 450px) {
    margin-right: 1rem;
  }
}

.skills-tooltip {
  max-width: 300px !important;
  background-color: #ffffff09 !important;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1) !important;
  border: 1px solid rgba(255, 255, 255, 0.18) !important;
  backdrop-filter: blur(5px);
  -moz-backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  color: var(--white-color) !important;
  text-align: center !important;
  opacity: 1 !important;

  @media screen and (min-width: 2000px) {
    font-size: 1.75rem !important;
    max-width: 500px !important;
    line-height: 2 !important;
  }
}

@-moz-document url-prefix() {
  .app__skills-item {
    div {
      background-color: #252528c8;
    }
  }

  .skills-tooltip {
    background-color: #252528c8 !important;
  }
}

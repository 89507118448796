.app__testimonial {
  flex: 1;
  width: 100%;
  flex-direction: column;
  margin-top: 2rem;
}

.app__testimonial-item {
  width: 60%;
  min-height: 320px;

  display: flex;
  padding: 2rem;

  margin: 4rem 0 0 0;

  background-color: #ffffff09;
  backdrop-filter: blur(5px);
  -moz-backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 0.25rem;

  &:hover {
    box-shadow: 0 0 25px #ffffff08;
  }

  transition: all 0.3s ease-in-out;

  img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    border: 4px solid var(--secondary-color);
  }

  @media screen and (min-width: 2000px) {
    min-height: 450px;
    img {
      width: 150px;
      height: 150px;
    }
  }
  @media screen and (max-width: 850px) {
    width: 100%;
  }
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.app__testimonial-content {
  flex: 1;
  height: 100%;
  padding: 0 2rem;
  text-align: left;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;

  p {
    font-size: 1.25rem;
    line-height: 2rem;
    color: var(--primary-color);
    font-family: var(--font-base);
  }

  h4 {
    font-weight: 600;
    color: var(--secondary-color);
    margin-top: 2rem;
  }

  h5 {
    font-weight: 400;
    color: var(--primary-color);
    margin-top: 5px;
  }

  @media screen and (max-width: 600px) {
    padding-top: 2rem;

    p {
      font-size: 1rem;
      line-height: 1.2rem;
    }
  }
}

.app__testimonial-btns {
  flex-direction: row;
  margin-top: 1rem;

  div {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #ffffff09;
    backdrop-filter: blur(5px);
    -moz-backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.18);

    margin: 1rem;
    transition: all 0.3s ease-in-out;

    svg {
      width: 20px;
      height: 20px;
      color: var(--secondary-color);
    }

    &:hover {
      background-color: var(--secondary-color);
      box-shadow: 0 0 25px #ffffff08;

      svg {
        color: var(--gray-color);
      }
    }
  }
}

.app__testimonials-brands {
  width: 80%;
  flex-wrap: wrap;
  margin-top: 2rem;

  div {
    width: 150px;
    margin: 1.5rem;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      filter: grayscale(1);

      transition: all 0.3s ease-in-out;
    }

    &:hover {
      img {
        filter: grayscale(0);
      }
    }

    @media screen and (min-width: 2000px) {
      width: 210px;
      margin: 2rem;
    }

    @media screen and (max-width: 450px) {
      width: 120px;
      margin: 1rem;
    }
  }

  @media screen and (max-width: 800px) {
    width: 100%;
  }
}

#home {
  position: relative;
  background: url(../../assets/bgIMG.png);
  background-size: cover;
  background-repeat: repeat;
  background-position: center;

  .app__wrapper {
    padding: 0;

    .copyright {
      display: none;
    }
  }
}

.app__header {
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: row;

  padding: 6rem 2rem 0;

  @media screen and (min-width: 2000px) {
    padding-top: 8rem;
  }
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
  @media screen and (min-width: 450px) {
    padding: 6rem 1rem 1rem;
  }
}

.app__header-info {
  flex: 0.65;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  height: 100%;

  @media screen and (max-width: 2000px) {
    width: 100%;
    margin-right: 0rem;
  }
}

.app__header-badge {
  width: 100%;
  margin-left: 10%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;

  z-index: 1;

  .badge-cmp,
  .tag-cmp {
    padding: 1rem 2rem;
    border-radius: 0.25rem;
    flex-direction: row;
    width: auto;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    background-color: #ffffff09;
    border: 1px solid rgba(255, 255, 255, 0.18);
    backdrop-filter: blur(5px);
    -moz-backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);

    p {
      color: var(--white-color);
    }
  }

  .badge-cmp {
    cursor: pointer;

    a {
      span {
        width: 100%;
        height: 100%;

        inset: 0;
        z-index: 1;
        position: absolute;
      }
    }
  }

  .tag-cmp {
    flex-direction: column;
    margin-top: 3rem;

    p {
      width: 100%;
      text-transform: uppercase;

      text-align: right;
    }
  }

  span {
    font-size: 2.5rem;
    @media screen and (min-width: 2000px) {
      font-size: 5rem;
    }
  }

  @media screen and (max-width: 1200px) {
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.app__header-circles {
  flex: 0.75;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;

  height: 100%;
  margin-left: 1rem;

  cursor: pointer;

  div {
    width: 100px;
    height: 100px;
    border-radius: 0.25rem;

    background-color: #ffffff09;
    backdrop-filter: blur(5px);
    -moz-backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.18);

    img {
      width: 60%;
      height: 60%;
    }
  }

  div:nth-child(1) {
    width: 100px;
    height: 100px;
  }

  div:nth-child(2) {
    margin: 1.75rem;
    width: 150px;
    height: 150px;
  }

  div:nth-child(3) {
    width: 70px;
    height: 70px;
  }

  @media screen and (min-width: 2000px) {
    div:nth-child(1) {
      width: 400px;
      height: 400px;
    }

    div:nth-child(2) {
      width: 170px;
      height: 170px;
    }

    div:nth-child(3) {
      width: 200px;
      height: 200px;
    }
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 0;

    div {
      margin: 1rem;
    }
  }

  @media screen and (max-width: 600px) {
    div {
      margin: 0;
    }

    div:nth-child(1) {
      width: 75px;
      height: 75px;
    }

    div:nth-child(2) {
      margin: 1rem;
      width: 100px;
      height: 100px;
    }

    div:nth-child(3) {
      width: 60px;
      height: 60px;
    }
  }
}

.app__header-img {
  flex: 1;
  // height: 100%;

  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 40rem;
  background-color: #ffffff09;
  backdrop-filter: blur(5px);
  -moz-backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 0.25rem;
  box-shadow: 0px 0px 40px #1d1d1d;
  padding: 2rem;
  margin: 0 auto;
  // margin-top: 10rem;

  img {
    width: 80%;
    height: 80%;
    object-fit: contain;
    z-index: 1;
    -webkit-filter: drop-shadow(0px 0px 10px #10101070);
    filter: drop-shadow(0px 0px 10px #10101070);
  }

  // .overlay_circle {
  //   position: absolute;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   z-index: 0;
  // }

  @media screen and (max-width: 1200px) {
    margin: 2rem 0;
  }
}

@-moz-document url-prefix() {
  .app__header-badge {
    .badge-cmp,
    .tag-cmp {
      background-color: #25252882;
    }
  }

  .app__header-circles {
    div {
      background-color: #25252882;
    }
  }

  .app__header-img {
    background-color: #25252882;
  }
}

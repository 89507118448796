@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=azeret+Neue&family=Lato:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Azeret+Mono:wght@600&family=Bebas+Neue&display=swap');

:root {
  --font-base: 'Lato', sans-serif;

  --primary-color: #edf2f8;
  --secondary-color: #fec859;
  --black-color: #1d1e1d;
  --lightGray-color: #1d1e1df7;
  --gray-color: #253e48;
  --brown-color: #f16979;
  --white-color: #f6f6f6;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
